@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@200&display=swap');
html {
  font-size: 8px;
}

body {
  margin: 0;
  font-family: 'Comfortaa', cursive;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
